import styled from "styled-components";

const Table = styled.table`
  thead {
    background-color: lightgrey;
  }

  td {
    border: 1px solid rgba(11, 31, 28, 0.48);

    padding: 0.5em;
  }
`;

export interface UtilityTableData {
  meter_number: string;
  meter_read_date: string;
  days: number;
  kwh: number;
  delivery_cost_cents: number;
  supply_cost_cents: number;
  total_cost: number;
}

export function TableForSubmission({
  utilityTableData,
}: {
  utilityTableData: UtilityTableData[];
}) {
  return (
    <div>
      <Table className="table">
        <tbody></tbody>
        <thead>
          <tr>
            <td>Meter #</td>
            <td>Meter Read Date</td>
            <td>Days</td>
            <td>Total Consumption (kWh)</td>
            <td>Delivery Cost</td>
            <td>Supply Cost</td>
            <td>Total Cost</td>
          </tr>
        </thead>
        <tbody>
          {utilityTableData.map((row: any) => {
            const {
              meter_number,
              meter_read_date,
              days,
              kwh,
              delivery_cost_cents,
              supply_cost_cents,
              total_cost_cents,
            } = row;
            return (
              <tr>
                <td>{meter_number}</td>
                <td>{meter_read_date}</td>
                <td>{days}</td>
                <td>{kwh}</td>
                <td>{delivery_cost_cents}</td>
                <td>{supply_cost_cents}</td>
                <td>{total_cost_cents}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
