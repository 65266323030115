import {Badge, Col, Container, Nav, Navbar, NavbarBrand, NavItem, Row} from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";

const WattLayoutContainer = styled.div`
  // Note: proper sticky footer depends on ALL parent containers up to <body> to also be 
  // height: 100%. See index.css to do that.
  height: 100%;
  
  display: flex;
  flex-direction: column;

  main {
    flex: 1 0 auto;
  }
  .watt-footer {
    flex-shrink: 0;
  }
`;

export function WattLayout({children}: {children: React.ReactNode}) {
  return (
    <WattLayoutContainer>
      <header className="bg-dark text-light">
        <Navbar>
          <div>
            <NavbarBrand tag="div">
              <Link to="/" className="text-light text-decoration-none">WattsTheRent</Link>
              <Badge pill color="secondary text-dark" className="mx-2">Beta</Badge>
            </NavbarBrand>

          </div>

          <Nav pills>
            <NavItem>
              <Link to={"/beta"} className="text-light">Sign Up</Link>
            </NavItem>
          </Nav>
        </Navbar>
      </header>

      <main>
        {children}
      </main>
      <footer className="watt-footer">
        <Container fluid>
          <Row>
            <Col md={8} xl={6} className="mx-auto mt-5 mb-3 text-center small">
              <div className="mb-3">
                <span>Contact Us:</span> <a href="mailto:support@wattstherent.com">support@wattstherent.com</a>
              </div>
              <span>(C) 2022 WattsTheRent.com <span>|</span> Energy solutions for all</span><br/>
            </Col>

          </Row>
        </Container>
      </footer>

    </WattLayoutContainer>
  );
}
