import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "shrike";
import { AddressContext } from "./App";
import styled from "styled-components";
import axios from "axios";
import { WattLayout } from "./Layout";
import { LoginWall } from "./LogInWall";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  width: 600px;
  margin: 0 auto;
  padding-top: 10%;
`;

const TenantInformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const NameContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const AddressBlock = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
`;

const CityStateZipBlock = styled.div`
  display: flex;
  width: 100%;
  gap: 60px;
  justify-content: flex-start;
`;

const CityInput = styled(Input)`
  width: 35%;
`;

const NameInput = styled(Input)`
  width: 80%;
`;

const StateInput = styled(Input)`
  width: 25%;
`;

const ZipInput = styled(Input)`
  width: 15%;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
  width: 25%;
`;

export function AddressForm() {
  const navigate = useNavigate();
  const [, setAddress] = useContext(AddressContext);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [returnedResidenceId, setReturnedResidenceId] = useState<string | null>(
    null
  );

  function submitAddress() {
    axios
      .post(`https://api.wattstherent.com/residences`, {
        first_name: firstName,
        last_name: lastName,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city: city,
        state: "Maine",
        zip_code: zip,
      })
      .then((res) => {
        setAddress &&
          setAddress({
            firstName: res.data.data.user.first_name,
            lastName: res.data.data.user.last_name,
            addressLine1: res.data.data.address_line_1,
            addressLine2: res.data.data.address_line_2,
            city: city,
            state: "Maine",
            zip: zip,
          });
        setReturnedResidenceId(res.data.data.id);
      });
  }

  useEffect(() => {
    if (returnedResidenceId) {
      return navigate(`/residence/${returnedResidenceId}/collect`);
    }
  }, [returnedResidenceId, navigate]);

  return (
    <WattLayout>
      <LoginWall>
        <div className="my-5">
        <FormContainer style={{paddingTop: 0}}>
          <h2>Request Information for Address</h2>
          <TenantInformationBlock>
            <NameContainer>
              <NameInput
                name={"first-name"}
                label={"First name"}
                placeholder={"Marvin"}
                value={firstName}
                onChange={(e: any) => setFirstName(e)}
                required={true}
              ></NameInput>
              <NameInput
                name={"last-name"}
                label={"Last name"}
                placeholder={"Moose"}
                value={lastName}
                onChange={(e: any) => setLastName(e)}
              ></NameInput>
            </NameContainer>
          </TenantInformationBlock>
          <AddressBlock>
            <Input
              name={"address-line-1"}
              label={"Address line 1 "}
              placeholder={"123 Maine St."}
              value={addressLine1}
              onChange={(e: any) => setAddressLine1(e)}
              required={true}
            ></Input>
            <Input
              name={"address-line-2"}
              label={"Address line 2"}
              placeholder={"Include apartment number or floor"}
              value={addressLine2}
              onChange={(e: any) => setAddressLine2(e)}

            ></Input>
          </AddressBlock>
          <CityStateZipBlock>
            <CityInput
              name={"city"}
              label={"City"}
              value={city}
              onChange={(e: any) => setCity(e)}
              required={true}
            ></CityInput>

            <StateInput
              name={"state"}
              label={"State"}
              value={"Maine"}
              disabled={true}
              onChange={(e: any) => setState(e)}
            ></StateInput>
            <ZipInput
              name={"zip"}
              label={"Zip"}
              value={zip}
              maxLength={5}
              inputMode={"numeric"}
              onChange={(e: any) => setZip(e)}
              required={true}
            ></ZipInput>
          </CityStateZipBlock>
          <SubmitButton onClick={() => submitAddress()}>Submit</SubmitButton>
        </FormContainer>
        </div>
      </LoginWall>
    </WattLayout>
  );
}
