import axios from "axios";
import React, { Component, useState, useEffect } from "react"
import {useParams} from 'react-router-dom';
import { LoginWall } from "./LogInWall";
import {WattLayout} from "./Layout";
import {Col, Container} from "reactstrap";

function withParams(Component: any) {
  return (props: JSX.IntrinsicAttributes) => <Component {...props} params={useParams()} />;
}

export function ViewResidence() {
  const { id } = useParams();
  const [residenceInfo, setResidenceInfo] = useState({
    id: null,
    address: {
      address_line_1: null,
      address_line_2: null,
      city: null,
      state: null,
      zip_code: null,
    },
    user: {
      first_name: null,
      last_name: null,
    },
    meter_number: null,
    utility_data: null,
    status: null, // 'empty', 'out-of-date', 'up-to-date'
  });

  const getResidenceData = async () => {
    const {data} = await axios.get(`https://api.wattstherent.com/residences/${id}`);

    setResidenceInfo(data.data);
    console.log("Full residence info:", data.data);
  }

  useEffect(() => {
    getResidenceData();
  }, []);

  const {user, address, status} = {...residenceInfo};

  return (
    <WattLayout>
      <LoginWall>

        <Container fluid>
          <Col xl={6} md={8} className="mx-auto my-4">
            <h1>Cost & Consumption for {address.address_line_1 || id}</h1>
            <code>WTR-{id}</code>
            <p>Requested for: <strong>{user.first_name} {user.last_name}</strong></p>

            <div className="bg-light p-3 mb-3">
              {address.address_line_1}<br/>
              {address.address_line_2}<br/>
              {address.city} {address.state} {address.zip_code}
            </div>

            <p>Status: <strong>{status}</strong></p>

            <p>Utility Data:</p>
            <div className="bg-light p-3">
              {JSON.stringify(residenceInfo.utility_data)}
            </div>

          </Col>
        </Container>

      </LoginWall>
    </WattLayout>
  );
}
