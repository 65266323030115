import "shrike/dist/shrike.css";
import styled, { ThemeProvider } from "styled-components";
import { arcadiaLate2021Theme } from "shrike";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Landing } from "./Landing";
import { DataSubmissionForm } from "./DataSubmissionForm";
import { AddressForm } from "./AddressForm";
import {ViewResidence} from "./ResidencePage";
import React, { createContext, useState } from "react";
import {BetaLanding} from "./BetaLanding";

const Wrapper = styled.div`
  /* Allows sticky footer in Layout.tsx */
  height: 100%;
`;

interface RequestContextValue {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export const AddressContext = createContext<
  | [
      RequestContextValue,
      React.Dispatch<React.SetStateAction<RequestContextValue>>
    ]
  | []
>([]);

function AddressContextWrapper({ children }: { children: React.ReactNode }) {
  const [request, setRequest] = useState<any>({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "Maine",
    zip: "",
  });

  return (
    <AddressContext.Provider value={[request, setRequest]}>
      {children}
    </AddressContext.Provider>
  );
}

function App() {
  return (
    <Wrapper>
      <AddressContextWrapper>
        <ThemeProvider theme={arcadiaLate2021Theme}>
          <Router>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path={`/residence`} element={<AddressForm />} />

              {/* Note: Viewing a residence we use a magic URL for hackathon-grade security-through-obscurity */}
              <Route path={`/view-residence/:id`} element={<ViewResidence />} />

              <Route
                path={`/residence/:id/collect`}
                element={<DataSubmissionForm />}
              />
              <Route path="/beta" element={<BetaLanding />} />
              <Route path="*" element={<BetaLanding />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </AddressContextWrapper>
    </Wrapper>
  );
}

export default App;
