import {Row, Col} from "reactstrap";
import {FTRow, FTCell} from "./InputFakeTable";

export function HeadersCMP() {
  return (
    <FTRow className="header">
      <FTCell>
        Meter #
      </FTCell>
      <FTCell>
        Meter Read Date
      </FTCell>
      <FTCell>
        Days
      </FTCell>
      <FTCell>
        Total Consumption (kWh)
      </FTCell>
      <FTCell>
        Delivery Cost
      </FTCell>
      <FTCell>
        Supply Cost
      </FTCell>
      <FTCell>
        Total Cost
      </FTCell>
    </FTRow>
  );
}
