import styled from "styled-components";
import {Container, Row, Col} from "reactstrap";

const FakeTable = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  input, textarea {
    // Override a few shrike things
    margin: 0;
    max-width: none;
    border-radius: 0;
    resize: vertical;
  }
`;

export const FTCell = styled.div`
  flex-grow: 1;
  
  border: 1px solid rgba(11,31,28,0.48);
  
  padding: 0.5em;
  &.ftc-input {
    padding: 0;
  }
`;

export const FTRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  
  &.header {
    ${FTCell} {
      background-color: lightgrey;
    }
  }
`;



export function InputFakeTable({children, headersRow}: {children: React.ReactNode, headersRow: React.ReactNode}) {
  return (
    <FakeTable>
      {headersRow}
      <FTRow>
        {children}
      </FTRow>

    </FakeTable>
  );
}
