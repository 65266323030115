import { FormGroup, Label, Input, Button, Form, Container, Col } from "reactstrap";
import {useState} from "react";
import {Link} from "react-router-dom";


export function LoginWall({children}: {children: React.ReactNode}) {
  // super duper Top Secret grade security here...
  const [isAuthed, setAuthed] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const [user, setUser] = useState("");
  const [pw, setPw] = useState("")

  const handleLogin = (e: { preventDefault: () => void; }) => {
    if (user == "42" && pw == "42") {
      setAuthed(true);
    } else {
      // @ts-ignore
      setErrorMsg("ERROR: Unknown Username or Password");
    }
    e.preventDefault();
  }

  if (isAuthed) {
    return (<>{children}</>);
  }

  return (
    <Container>
      <Col xl={4} md={6} className="mx-auto my-5">
        <div className="my-5 mx-auto">
          <h1>Login</h1>
          <Form>
            <FormGroup>
              <Label for="username">
                Username
              </Label>
              <Input
                id="username"
                name="username"
                type="text"
                value={user}
                onChange={(e) => {setUser(e.target.value);}}
              />
            </FormGroup>

            <FormGroup>
              <Label for="signupEmail">
                Password
              </Label>
              <Input
                id="pw"
                name="pw"
                type="password"
                value={pw}
                onChange={(e) => {setPw(e.target.value);}}
              />
            </FormGroup>

            <div className="text-center mt-3">
              <Button color="primary" onClick={handleLogin} type="submit">Login</Button>
            </div>
            <div className="text-center mt-2">
              or <Link to="/beta">Sign Up</Link>
            </div>
            {errorMsg ? (
              <div className="bg-danger p-3 mt-3 text-light">
                {errorMsg}
              </div>
            ) : (<> </>)}
          </Form>
        </div>
      </Col>
    </Container>
  );
}
