import { Link } from "shrike";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { WattLayout } from "./Layout";

const HeroBanner = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/fireplace_unsplash.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  color: #f0f0f0;
`;

export function Landing() {
  return (
    <WattLayout>
        <HeroBanner>
          <Container>
            <Row>
              <Col lg={8} className="mx-auto px-4 py-5 my-5 text-center">
                <h1>Winter's Cold Enough Forya?</h1>
                <p className="lead">
                  Maine renters have the right to obtain a full 12-month history
                  of energy consumption and costs for potential apartments. We
                  help you obtain that information and find the most
                  energy-efficient unit.
                </p>
              </Col>
            </Row>
          </Container>
        </HeroBanner>

        <Container fluid className="my-5">
          <Row>
            <Col md={8} xl={6} className="mx-auto">
              <p>
                Did you know that by Maine state energy efficiency laws,
                utilities must provide prospective tenants who will be paying
                utility bills information on how much it has cost to heat a
                unit?
              </p>

              <figure className="float-end mx-5">
                <blockquote className="blockquote">
                  "You have the right to obtain a 12-month history of energy
                  consumption and the cost of that consumption from the energy
                  supplier."
                </blockquote>
                <figcaption className="blockquote-footer">
                  Maine Statute{" "}
                  <a
                    href="https://legislature.maine.gov/statutes/14/title14sec6030-C.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Title 14 §6030-C
                  </a>
                </figcaption>
              </figure>
              <p>
                <strong>WattsTheRent</strong> helps Maine prospective renters
                collect complete energy efficiency data from the right utilities
                so you can compare units and make the right choice based on your
                budget.
              </p>
              <p className="text-center mt-5">
                <Link to={`/residence`}>Submit Data Request for Rental</Link>
              </p>
            </Col>
          </Row>
        </Container>
    </WattLayout>
  );
}
