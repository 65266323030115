function isParseable(utilityData: string) {
  const meterCodeRegex = /^\w{10}\s\w+\/\w+\/\w+\s\w+\s\w+\s\w+.\w+/;
  const headingRegex = /^(meter) (#|number)/i;
  return meterCodeRegex.test(utilityData) || headingRegex.test(utilityData);
}

function hasHeaders(utilityData: string) {
  const headingRegex = /^(meter) (#|number)/i;
  return headingRegex.test(utilityData);
}

function createViewTable(utilityData: string) {
  const dataForTableView: any[] = [];

  const lines = utilityData.split(/\n/);
  const rows = lines.map((row: string) => row.split(/\t/));

  // strip away headers, if they're pasted in
  const dataHasHeaders = hasHeaders(utilityData);
  const startingIndex = dataHasHeaders ? 1 : 0;

  rows.slice(startingIndex).map((row: any) => {
    let rowObject = {
      meter_number: row[0],
      meter_read_date: row[1],
      days: row[2],
      kwh: row[3],
      delivery_cost_cents: row[4],
      supply_cost_cents: row[5],
      total_cost_cents: row[6],
    };
    return dataForTableView.push(rowObject);
  });

  return dataForTableView;
}

function createSubmissionTable(utilityData: string) {
  const dataForSubmission: any[] = [];

  const lines = utilityData.split(/\n/);
  const rows = lines.map((row: string) => row.split(/\t/));

  rows.map((row: any) => {
    let rowObject = {
      meter_read_date: row[1],
      days: parseInt(row[2]),
      kwh: parseFloat(row[3]),
      delivery_cost_cents: parseFloat(row[4]) * 100,
      supply_cost_cents: parseFloat(row[5]) * 100,
      total_cost_cents: parseFloat(row[6]) * 100,
    };
    return dataForSubmission.push(rowObject);
  });

  return dataForSubmission;
}

export function utilityDataParser(pastedUtilityData: string) {
  if (!isParseable(pastedUtilityData)) {
    // the data's not what we expected
    return {
      dataIsParseable: false,
      dataForTableView: [],
      dataForSubmission: [],
    };
  }

  // data format looks okay!
  const dataForTableView = createViewTable(pastedUtilityData);
  const dataForSubmission = createSubmissionTable(pastedUtilityData);

  return { dataIsParseable: true, dataForTableView, dataForSubmission };
}
