import styled from "styled-components";
import { FTCell, InputFakeTable } from "./inputTables/InputFakeTable";
import { HeadersCMP } from "./inputTables/UtilityHeadersCMP";
import { TableForSubmission } from "./TableForSubmission";

const ResultBox = styled.div`
  border: 1px solid rgba(11, 31, 28, 0.48);
  padding: 1rem;
  min-height: 8rem;
  min-width: 100%;
  color: #a9a9a9;
`;

export function ResultWrapper({
  dataIsParseable,
  dataForTableView,
}: {
  dataIsParseable: boolean;
  dataForTableView: any;
}) {
  if (dataIsParseable) {
    return (
      <TableForSubmission
        utilityTableData={dataForTableView}
      ></TableForSubmission>
    );
  } else
    return (
      <InputFakeTable headersRow={<HeadersCMP />}>
        <ResultBox className="ftc-input">{dataForTableView}</ResultBox>
      </InputFakeTable>
    );
}
