import {WattLayout} from "./Layout";
import {Col, Container, Form, FormGroup, Input, Label, Button} from "reactstrap";
import { useState } from "react";

export function BetaLanding() {
  const [submitted, setSubmitted] = useState<any>(false);

  const handleSubmit = () => {setSubmitted(true);}

  return (
    <WattLayout>
      <Container fluid>
        <Col xl={4} md={6} className="mx-auto my-5">
          <div className="text-center my-4">
            <h2>Sign Up for our Waitlist</h2>
          </div>

          <p>
            <strong>WattsTheRent</strong> is currently in limited beta.
          </p>
          <p>
            Enter your name and email to sign up for our waitlist and we'll help you find an apartment
            before winter hits this year.
          </p>

          { !submitted ? (
            <div className="px-3">
              <Form>
                <FormGroup>
                  <Label for="signupName">
                    Name
                  </Label>
                  <Input
                    id="signupName"
                    name="signupName"
                    placeholder="Marvin Moose"
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="signupEmail">
                    Email
                  </Label>
                  <Input
                    id="signupEmail"
                    name="signupEmail"
                    placeholder="moose@mainer.com"
                    type="email"
                  />
                </FormGroup>

                <div className="text-center mt-3">
                  <Button color="primary" onClick={handleSubmit}>Sign Up for Waitlist</Button>
                </div>
              </Form>
            </div>
          ) : (
            <div className="px-3 py-4 bg-light">
              <strong>Thanks for Signing Up!</strong> We'll let you know when your spot in the waitlist opens up!
            </div>
          )}
        </Col>
      </Container>
    </WattLayout>
  );
}
