import { useState, useEffect } from "react";
import { utilityDataParser } from "./utils";
import { Link, TextArea } from "shrike";
import { TableForSubmission } from "./TableForSubmission";
import { useParams } from "react-router-dom";
import { WattLayout } from "./Layout";
import { Col, Container, Button } from "reactstrap";
import { HeadersCMP } from "./inputTables/UtilityHeadersCMP";
import { InputFakeTable, FTCell } from "./inputTables/InputFakeTable";
import { ResultWrapper } from "./ResultWrapper";

import axios from "axios";

interface User {
  first_name: string;
  last_name: String;
}

interface Address {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
}

interface UtilityRequest {
  user: User;
  address: Address;
}

export function DataSubmissionForm() {
  const [requestData, setRequestData] = useState<UtilityRequest>({
    user: { first_name: "", last_name: "" },
    address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
    },
  });
  const { id } = useParams();
  const [dataIsParseable, setDataIsParseable] = useState<boolean>(false);
  const [dataForTableView, setDataForTableView] = useState<any>();
  const [pastedText, setPastedText] = useState<any>();
  const [dataForSubmission, setDataForSubmission] = useState<any>();
  const [submitted, setSubmitted] = useState<any>();
  const [submissionError, setSubmissionError] = useState<any>();

  const handlePaste = (event: {
    clipboardData: { getData: (arg0: string) => any };
  }) => {
    const pastedText = event.clipboardData.getData("text");
    const results = utilityDataParser(pastedText);

    const { dataIsParseable, dataForTableView, dataForSubmission } = results;

    const dataForDisplay = dataIsParseable ? dataForTableView : pastedText;

    setPastedText(pastedText);
    setDataIsParseable(dataIsParseable);
    setDataForTableView(dataForDisplay);
    setDataForSubmission(dataForSubmission);
  };

  const handleClear = () => {
    setDataForTableView(null);
    setSubmitted(false);
    setSubmissionError(false);
  };

  const handleSubmit = () => {
    axios
      .post(`https://api.wattstherent.com/residences/${id}/utility_data`, {
        raw_clipboard_data: pastedText,
        parsed_data: dataForSubmission,
        raw_row_data: { test: "from FE" },
      })
      .then((res) => {
        setSubmitted(true);
        setSubmissionError(false);
      })
      .catch((err) => {
        setSubmissionError(true);
      });
  };

  const getRequestData = async () => {
    const { data } = await axios.get(
      `https://api.wattstherent.com/residences/${id}`
    );
    setRequestData(data.data);
  };

  useEffect(() => {
    getRequestData();
  }, []);

  const {
    user: { first_name: firstName, last_name: lastName },
    address: {
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city,
      zip_code: zip,
    },
  } = { ...requestData };

  /*
            {submissionError && (<div className="my-5 fs-8 text-danger"> <strong>There's an error submitting the data. Please click the clear button below and try a different format ensuring data is copied from columns and rows that match the headers provided.</strong></div>)}
  */

  const displayPrompt = dataForTableView ? (
    <p>
      Below is your pasted data.{" "}
      <strong>Please ensure that it looks correct.</strong>
    </p>
  ) : (
    <p>
      Please paste <strong>the full 12-month</strong> consumption & cost
      information for the above address:
    </p>
  );

  return (
    <WattLayout>
      <Container fluid>
        <Col xl={6} md={8} className="mx-auto my-4">
          {submitted && !submissionError ? (
            <div className="my-5 fs-8">
              {" "}
              <strong>
                The data has been successfully submitted. Thanks for providing
                this information.
              </strong>
            </div>
          ) : (
            <div>
              <div>
                <h2>
                  Maine §6030-C 12-month Energy Consumption & Cost Request
                </h2>
                <p>
                  According to Maine Statute{" "}
                  <a
                    href="https://legislature.maine.gov/statutes/14/title14sec6030-C.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Title 14 §6030-C
                  </a>
                  , "a prospective tenant who will be paying utility costs has
                  the right to obtain from an energy supplier for the unit
                  offered for rental the amount of consumption and the cost of
                  that consumption for the prior 12-month period".
                </p>
                <p>
                  {firstName || lastName ? (
                    <>
                      On behalf of prospective tenant{" "}
                      <strong>
                        {firstName} {lastName}
                      </strong>
                      ,{" "}
                    </>
                  ) : (
                    <>On behalf of a prospective tenant, </>
                  )}
                  WattsTheRent is requesting <strong>the full 12-month</strong>{" "}
                  consumption & cost data for the following unit being offered
                  for rental:
                </p>
                <div className="mx-3 my-3 p-3 bg-light">
                  <div>
                    {addressLine1} {addressLine2}
                  </div>
                  <div>
                    {city}, ME {zip}
                  </div>
                  <div className="mt-3">Request ID: WTR-{id}</div>
                </div>
              </div>

              {displayPrompt}

              {dataForTableView ? (
                <>
                  <ResultWrapper
                    dataForTableView={dataForTableView}
                    dataIsParseable={dataIsParseable}
                  />
                  <div className="text-center" style={{ paddingTop: "1rem" }}>
                    <Button color="white" onClick={handleClear}>
                      Clear
                    </Button>
                    <Button
                      color="primary"
                      className="mx-3"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <InputFakeTable headersRow={<HeadersCMP />}>
                    <FTCell className="ftc-input">
                      <TextArea
                        label=""
                        name="Submission Form"
                        id="utility-data-submission"
                        onChange={() => console.log("receiving")}
                        onPaste={(e) => handlePaste(e)}
                        placeholder="Please paste full 12-month history table"
                      ></TextArea>
                    </FTCell>
                  </InputFakeTable>
                </>
              )}

              {submissionError && (
                <div className="my-5 fs-8 bg-danger text-light p-3">
                  <strong>
                    Uh-oh! There was an error submitting the data. Please click
                    the clear button and try a different format ensuring data is
                    copied from columns and rows that match the headers
                    provided.
                  </strong>
                </div>
              )}
            </div>
          )}

          <div className="my-5">
            <div className="mx-auto pt-5" style={{ width: "40%" }}>
              <hr />
            </div>
            <h2>About Us</h2>
            <p>
              <strong>WattsTheRent</strong> is an organization helping
              prospective Maine renters find the most energy efficient
              apartments. We help prospective tenants collect energy efficiency
              data and provide data analysis services so that energy costs are
              predictable and affordable.
            </p>
            <Link hideIcon={true} to="/">
              Learn More
            </Link>
          </div>
        </Col>
      </Container>
    </WattLayout>
  );
}
